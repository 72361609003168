.closeIcon {
  color: #202020;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  border-top: 3px solid #cecece;
}
.footer .btn-danger {
  background-color: #d2cfcf;
  min-width: 80px;
  border: none;
}
.footer div .btn-default {
  background-color: #d2cfcf;
  min-width: 80px;
  border: none;
}
.footer div .btn-primary {
  background: linear-gradient(to top, #0f8afb 0%, #3c40ef 100%);
  min-width: 80px;
  color: #fff;
  border: none;
}
.footer div button:last-child {
  margin-left: 10px;
}
