.scrollable-study-thumbnails {
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  background-color: #141414;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  width: 100%;
  overflow: auto;
  height: auto;
  border-radius: 16px;
}
.scrollable-study-thumbnails .thumbnail-container {
  padding-bottom: 1.5rem;
  padding: 0;
  margin-top: 0.5rem;
}
.scrollable-study-thumbnails .thumbnail-container:not(:last-child) {
  margin-right: 0.5rem;
}
.scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
