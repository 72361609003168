.measurementTable {
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  border-radius: 16px;
}
.measurementTable .maxMeasurements {
  border-radius: 3px;
  color: var(--primary-background-color);
  font-size: 12px;
  font-weight: 500;
  height: 19px;
  line-height: 17px;
  margin-top: 22px;
  margin-right: 15px;
  padding: 2px 6px 0;
  text-transform: uppercase;
  transition: all 0.3s ease;
  background-color: var(--default-color);
}
.measurementTable .measurementTableHeader {
  padding-left: 0px;
}
.measurementTable .measurementTableHeader .measurementTableHeaderItem {
  display: inline-block;
  margin-top: 9px;
  margin-left: 9px;
  padding-left: 9px;
  width: 90px;
  font-size: 14px;
  line-height: 20px;
  border-left: 1px solid var(--text-secondary-color);
  color: var(--text-primary-color);
}
.measurementTable .measurementTableHeader .measurementTableHeaderItem .timepointLabel {
  color: var(--text-secondary-color);
  font-size: 12px;
}
.measurementTable .measurementTableHeader .warning-status {
  display: block;
  position: absolute;
  width: 50px;
  height: 40px;
  padding-left: 7px;
  left: 15px;
  top: 7px;
}
.measurementTable .measurementTableHeader .warning-status .warning-border {
  display: block;
  border: 2px solid #e29e4a;
  border-radius: 36px;
  padding: 6px 7px;
  width: 40px;
  height: 40px;
}
.measurementTable .measurementTableHeader .warning-status svg {
  color: #e29e4a;
  width: 22px;
  height: 20px;
  pointer-events: inherit;
}
.measurementTable .measurementTableFooter {
  padding: 10px;
  color: #fff;
  text-align: center;
  z-index: 1;
}
.measurementTable .measurementTableFooter .saveBtn {
  border: 1px solid var(--ui-border-color-active);
  border-radius: 25px;
  background-color: var(--active-color);
  color: var(--ui-gray-dark);
  line-height: 25px;
  padding: 0 20px;
  outline: none;
  cursor: pointer;
}
.measurementTable .measurementTableFooter .saveBtn:hover,
.measurementTable .measurementTableFooter .saveBtn:active {
  background-color: var(--ui-sky-blue);
}
.measurementTable .measurementTableFooter .saveBtn svg {
  margin-right: 4px;
  position: relative;
  top: 2px;
}
.tooltip-warning.in {
  opacity: 1;
}
.tooltip-warning .tooltip-inner {
  padding: 0;
}
.tooltip-warning .tooltip-inner .warningTitle {
  text-align: left;
  background-color: var(--ui-gray-darkest);
  padding: 7px 15px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.tooltip-warning .tooltip-inner .warningContent {
  text-align: center;
  background-color: var(--ui-gray);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.tooltip-warning .tooltip-inner .warningContent ol {
  margin-top: auto;
  padding-inline-start: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  text-align: left;
}
.tooltip-warning .tooltip-inner .warningContent ol li {
  padding-right: 10px;
}
