.pagination-area {
  display: flex;
  color: var(--text-secondary-color);
  font-size: 13px;
  font-weight: normal !important;
  background-color: #e7e3e3;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.pagination-area label {
  font-weight: normal;
}
.pagination-area select {
  margin: 0 0.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  color: #7d7d7d;
  outline: none;
  height: 38px;
  width: 90px;
  border: none;
  padding: 0 0.25rem;
}
.pagination-area .form-group .pagination-text,
.pagination-area .form-group .pagination-text-row {
  color: #202020;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
}
.pagination-area .form-group .pagination-text-row {
  color: #7d7d7d;
}
.rows-per-page label.wrapperLabel {
  display: inline-table !important;
  margin: 0 4px;
}
.rows-per-page label.wrapperLabel select {
  margin: 0px 0.5rem;
  width: 52px;
  border-radius: 0.5rem;
  border: none;
}
.page-buttons {
  margin: 0;
  text-align: right;
  font-weight: normal;
}
.page-buttons ul.pagination-control {
  margin: 0;
}
.page-buttons ul.pagination-control li {
  display: table-cell;
  padding: 5px 2px;
}
.page-buttons ul.pagination-control li button {
  padding: 4px 8px;
  background: linear-gradient(to right, #1285fa 0%, #3cf 100%);
  border-color: var(--ui-gray);
  color: var(--ui-gray-darkest);
  color: #fff;
  text-decoration: none;
  border: none;
  min-width: 84px;
  padding: 0.5rem;
  font-weight: 600;
}
.page-buttons ul.pagination-control li .active button {
  background-color: var(--ui-gray);
  border-color: #ddd;
  color: #fff;
}
.page-buttons ul.pagination-control li .prev-btn {
  background: #d2cfcf !important;
  color: #202020;
}
