.more-right {
  display: none !important;
}
.custom-toolbar-button svg {
  fill: #fff;
}
.toolbar-button {
  float: left;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #086eaf !important;
  margin: 0px 3px;
  border-radius: 8px;
  background: #000;
  box-shadow: #191919 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 32px;
  height: 32px;
  border: 1px solid #086eaf !important;
  position: relative;
}
.toolbar-button .toolbar-button-label {
  font-weight: 500;
  font-size: 0.7rem;
  position: absolute;
  top: 35px;
}
.toolbar-button .expand-caret {
  width: 8px;
  height: 8px;
  transform: translate(2px, 2px);
}
.toolbar-button svg {
  height: 23px;
  width: 23px;
}
.toolbar-button:hover {
  background: #086eaf !important;
  color: #4d4cfe;
  fill: #4d4cfe;
}
.toolbar-button.active,
.toolbar-button:active {
  background: #086eaf !important;
}
.toolbar-button-label svg:hover {
  transform: scale(1.2);
}
.toolbar-button-lbl {
  position: absolute;
  top: 30px;
/* height: 12px; */
  font-size: 10px;
}
.toolbar-button-lbl .labelled {
  margin: 0 0;
  margin-bottom: -8px;
  color: #fff;
}
